import MarkDownRender from "../../MarkDownRender";
import { useSelector } from "react-redux";
import { forwardRef } from "react";

const ChatMessageItemBob = forwardRef(
  ({ chat, dataArrayIndex }, olderMessageScrollRef) => {
    const themeColors = useSelector(
      (state) => state.app.theme.color.theme_colors
    );
    const older_message_index = useSelector(
      (state) => state.app.chat.older_message_index
    );
    const commonClassNames = "flex flex-col rounded-tl-sm rounded-lg p-3";
    if (chat.text) {
      return (
        <div
          className="flex flex-col items-start w-9/12"
          ref={olderMessageScrollRef}
        >
          <div
            className={`${commonClassNames}`}
            style={{
              backgroundColor: themeColors.answer_background.base,
              width: "fit-content",
            }}
          >
            <MarkDownRender compact noEllipsisFilter source={chat.text} />
          </div>
        </div>
      );
    }
  }
);
export default ChatMessageItemBob;
