import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import { GraphQLClient } from "graphql-request";
import axios from "axios";
import io from "socket.io-client";
import resources from "./components/Pane/AdaptiveLearning/3wa";
import {
  GQL_SUBSCRIBE_MA_USER,
  GQL_CHECK_EXTERNAL_USER_ID,
  GQL_GET_BOB_CUSTOM_WITH_COURSE_ID,
  GQL_GET_NB_MEMORY_ANCHORING_QUESTION,
  GQL_GET_NEXT_MEMORY_ANCHORING_QUESTION,
  GQL_GET_USER_STUDENT_METRICS,
  GQL_GET_ANSWER_RATE,
  GQL_RATE_ANSWER,
  GQL_ASK_TEACHER,
  GQL_GET_QUESTION_TAG_TYPE,
  GQL_GET_QUESTIONS_FOR_TEACHER,
  GQL_GET_CONVERSATION,
  GQL_SET_READ_MESSAGE,
  GQL_GET_MA_USER_STATUS,
  GQL_ANSWER_MEMORY_ANCHORING_QUESTION,
  GQL_LIST_3WA_AL_CONCEPTS,
  GQL_SEND_3WA_AL_SCORE,
  GQL_GET_BOB_CHAT_HISTORY,
  GQL_SAVE_MESSAGE,
} from "./api/gql";

import {
  XnewChatMessage,
  XsetALStage,
  XgetTeacherConversations,
  XgetUserStudentMetrics,
  XgetKnowledgeAnchoringUserStatus,
  XgetNextKAQuestion,
  XsetKnowledgeAnchoringStage,
  XpostLogEvent,
  XgetBobChatHistory,
  XsaveChatMessage,
  XsetChatLoading,
} from "./appSlice";

const configStage = process.env.REACT_APP_STAGE;
var endPoint;
if (configStage === "prod" || configStage === "review") {
  endPoint = "https://api.professorbob.ai";
} else if (configStage === "dev") {
  endPoint = "https://api-dev.professorbob.ai";
} else {
  endPoint = "http://localhost:5001";
}
var gqlUri = endPoint + "/student";
const client = new GraphQLClient(gqlUri, {
  headers: { authorization: "Bearer Bob Student" },
});
const rest_client = axios.create({
  baseURL: endPoint,
  timeout: 1000,
  headers: { authorization: "Bearer BobLogToken" },
});

const socket = io("wss://ws.professorbob.ai");

const socketMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case "app/sendQuestionToBob":
        const payload = {
          chat: {
            text: action.payload,
          },
          conversationID: 1,
          chapterID: getState().app.id.external_chapter,
        };
        socket.emit("ask-bob", payload);
        break;
      case "app/initSocket":
        socket.on("new-chat", (message) => {
          console.log("confirm msg sent", message);
          dispatch(
            XsaveChatMessage({
              msg_type: "questionToBob",
              content: message?.chat?.text || null,
            })
          );

          dispatch(
            XnewChatMessage({
              chat: {
                text: message?.chat?.text || null,
                type: "chat",
                original_question: message?.chat?.original_question || null,
              },
              timestamp: new Date().getTime(),
              user: {
                userID: "1",
              },
            })
          );
        });
        socket.on("bob-msg", (message) => {
          console.log("new bob message", message);
          if (
            !(
              message?.chat?.text?.includes("some similar") ||
              message?.chat?.text?.includes("Voici une liste") ||
              message?.chat?.text?.includes("I've found") ||
              message?.chat?.text?.includes("J'ai trouvé une réponse") ||
              message?.chat?.text?.includes("voici quelques")
            )
          ) {
            if (message?.chat?.type === "rasa-intent") {
            } else {
              if (message?.chat?.answer || message?.chat?.text) {
                dispatch(
                  XsaveChatMessage({
                    msg_type: "answerFromBob",
                    content:
                      message.chat.type === "answer"
                        ? message.chat.answer.text
                        : message.chat.text || null,
                    answer_id:
                      message.chat.type === "answer"
                        ? message.chat.answer.external_id
                        : null,
                    meta_data:
                      message.chat.type === "answer"
                        ? {
                            source_type:
                              message.chat?.answer?.source_type || null,
                            uri: message.chat?.answer?.uri || null,
                            start_time_in_milliseconds:
                              message.chat?.answer
                                ?.start_time_in_milliseconds || null,
                            end_time_in_milliseconds:
                              message.chat?.answer?.end_time_in_milliseconds ||
                              null,
                            original_question:
                              message?.chat?.original_question || null,
                            associated_question:
                              message?.chat?.associated_question || null,
                          }
                        : {},
                  })
                );
                dispatch(
                  XpostLogEvent({
                    event_type:
                      getState().app.chat.question_for_log_buffer?.event_type ||
                      null,
                    input:
                      getState().app.chat.question_for_log_buffer?.input ||
                      null,
                    origin:
                      getState().app.chat.question_for_log_buffer?.origin ||
                      null,
                    from:
                      getState().app.chat.question_for_log_buffer?.from || null,
                    userInput:
                      getState().app.chat.question_for_log_buffer?.userInput ||
                      null,
                    answer_type: message.chat.type,
                    answer_id: message.chat?.answer?.external_id || null,
                    associated_question:
                      message?.chat?.associated_question || null,
                  })
                );
              }
              dispatch(
                XnewChatMessage({
                  answer: message?.chat?.answer || null,
                  chat: {
                    text: message?.chat?.text || null,
                    type: message?.chat?.type || null,
                    original_question: message?.chat?.original_question || null,
                    associated_question:
                      message?.chat?.associated_question || null,
                  },
                  related_questions: message?.chat?.related_questions,
                  timestamp: new Date().getTime(),
                  user: {
                    userID: String(message?.chat?.user?.userid) || null,
                  },
                })
              );
            }
          }
        });
        break;
      case "app/closeSocket":
        socket.disconnect();
        break;
      default:
        break;
    }
    return next(action);
  };

const asyncMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case "app/XgetALConcepts":
        const al_data = await client.request(GQL_LIST_3WA_AL_CONCEPTS, {
          external_client_id: getState().app.id.external_client,
          external_user_id: getState().app.id.external_user,
        });
        if (!al_data.list3waALConcepts.error) {
          const buffer_concepts = al_data.list3waALConcepts.data;
          var new_buffer_concepts = [];
          buffer_concepts.forEach((concept) => {
            if (Object.keys(resources).includes(concept.label)) {
              if (new_buffer_concepts.length > 0) {
                if (
                  !concept.score &&
                  (!new_buffer_concepts[new_buffer_concepts.length - 1].score ||
                    new_buffer_concepts[new_buffer_concepts.length - 1]
                      .score === -1)
                ) {
                  new_buffer_concepts.push({
                    label: concept.label,
                    id: concept.id,
                    score: -1,
                  });
                } else {
                  new_buffer_concepts.push(concept);
                }
              } else {
                new_buffer_concepts.push(concept);
              }
            }
          });
          console.log("buffer_concepts", new_buffer_concepts);
          action.payload = new_buffer_concepts;
        } else {
          dispatch(XsetALStage("error"));
        }
        break;
      case "app/XpostALScore":
        await client.request(GQL_SEND_3WA_AL_SCORE, {
          external_client_id: getState().app.id.external_client,
          external_user_id: getState().app.id.external_user,
          al_concept_id: getState().app.adaptive_learning.current_concept.id,
          score: getState().app.adaptive_learning.score,
        });
        break;
      case "app/XsetExternalIDs":
        const data = await client.request(GQL_CHECK_EXTERNAL_USER_ID, {
          external_user_id: action.payload.user,
          corpus_id: action.payload.corpus,
          chapter_id: action.payload.chapter,
        });
        console.log("checked user", data.checkOrCreateExternalUserId);

        const data_custom = await client.request(
          GQL_GET_BOB_CUSTOM_WITH_COURSE_ID,
          {
            external_course_id: action.payload.course,
            external_corpus_id: action.payload.corpus,
            tag: "prod",
          }
        );

        if (data_custom?.getBobCustomization?.external_client_id) {
          dispatch(
            XgetBobChatHistory({
              external_user_id: data.checkOrCreateExternalUserId || null,
              external_course_id: action.payload.course,
              external_client_id:
                data_custom.getBobCustomization.external_client_id || null,
            })
          );
        }

        action.payload = {
          checked_user: data.checkOrCreateExternalUserId || null,
          client: "",
          course: action.payload.course,
          corpus: action.payload.corpus,
          chapter: action.payload.chapter,
          custom: data_custom.getBobCustomization,
        };
        break;
      case "app/XgetBobChatHistory":
        dispatch(XsetChatLoading());
        const data_history = await client.request(GQL_GET_BOB_CHAT_HISTORY, {
          external_user_id:
            action?.payload?.external_user_id ||
            getState().app.id.external_user,
          external_course_id:
            action?.payload?.external_course_id ||
            getState().app.id.external_course,
          external_client_id:
            action?.payload?.external_client_id ||
            getState().app.id.external_client,
          limit: 25,
          offset: getState().app.chat.history_offset,
        });
        if (
          data_history?.getBobChatHistory?.length === 0 &&
          !getState().app.chat.external_first_message_id
        ) {
          dispatch(
            XsaveChatMessage({
              msg_type: "answerFromBob",
              content: "",
            })
          );
        } else {
          data_history?.getBobChatHistory.forEach((message, index) => {
            if (
              message.content?.length > 0 ||
              message?.meta_data?.source_type === "video"
            ) {
              dispatch(
                XnewChatMessage({
                  answer: message.external_answer_id
                    ? {
                        external_id: message.external_answer_id,
                        text: message.content,
                        source_type: message?.meta_data?.source_type || null,
                        uri: message?.meta_data?.uri || null,
                        start_time_in_milliseconds:
                          message?.meta_data?.start_time_in_milliseconds ||
                          null,
                        end_time_in_milliseconds:
                          message?.meta_data?.end_time_in_milliseconds || null,
                      }
                    : null,
                  chat: {
                    text: message.external_answer_id ? null : message.content,
                    type: message.external_answer_id ? "answer" : "chat",
                    original_question:
                      message?.meta_data?.original_question || null,
                    associated_question:
                      message?.meta_data?.associated_question || null,
                  },
                  external_id: message.external_id,
                  related_questions: null,
                  timestamp: message?.sent_at || null,
                  user: {
                    userID: message.msg_type === "questionToBob" ? "1" : "0",
                  },
                })
              );
            }
            //if(index === data_history.getBobChatHistory.length){scrollto la end ref, puis changer la ref}
          });
          action.payload = {
            external_first_message_id:
              data_history?.getBobChatHistory[0]?.external_first_message_id ||
              data_history?.getBobChatHistory[0]?.external_id,
          };
        }
        break;
      case "app/XsaveChatMessage":
        const message_saved = await client.request(GQL_SAVE_MESSAGE, {
          msg_type: action.payload.msg_type,
          external_user_id: getState().app.id.external_user,
          content: action.payload.content,
          external_previous_message_id: null,
          external_first_message_id:
            getState().app.chat?.external_first_message_id || null,
          external_question_id: null,
          external_answer_id: action.payload.answer_id || null,
          external_question_answer_id: null,
          external_client_id: getState().app.id.external_client,
          external_course_id: getState().app.id.external_course,
          meta_data: action.payload?.meta_data || null,
        });
        action.payload = message_saved.saveMessageBobFromBobChat;
        break;
      case "app/XgetUserStudentMetrics":
        var data_nb_ka_question;
        const data_user_metrics = await client.request(
          GQL_GET_USER_STUDENT_METRICS,
          {
            external_user_id: getState().app.id.external_user,
            external_course_id: getState().app.id.external_course,
          }
        );
        if (getState().app.general.knowledge_anchoring_visible) {
          data_nb_ka_question = await client.request(
            GQL_GET_NB_MEMORY_ANCHORING_QUESTION,
            {
              external_user_id: getState().app.id.external_user,
            }
          );
          if (
            data_nb_ka_question?.getNumberOfMaQuestions >
            getState().app.notification.knowledge_anchoring
          ) {
            dispatch(XsetKnowledgeAnchoringStage(1));
            dispatch(XgetNextKAQuestion());
          }
        }
        if (
          getState().app.notification.teacher <
            data_user_metrics?.getUserStudentMetrics?.unreadMessages &&
          getState().app.teacher.conversations.length > 0
        ) {
          dispatch(XgetTeacherConversations());
        }

        action.payload = {
          teacher_notification:
            data_user_metrics?.getUserStudentMetrics?.unreadMessages || 0,
          knowledge_notification:
            data_nb_ka_question?.getNumberOfMaQuestions || 0,
          exam_mode:
            data_user_metrics?.getUserStudentMetrics?.evaluationMode || false,
        };
        break;

      case "app/XgetTeacherConversations":
        const data_teacher_conversations = await client.request(
          GQL_GET_QUESTIONS_FOR_TEACHER,
          {
            external_user_id: getState().app.id.external_user,
          }
        );
        const conversations =
          data_teacher_conversations?.getAllTheConversationWithTeacher || [];
        var data_conversation = null;
        action.payload = await Promise.all(
          conversations.map(async (conversation) => {
            data_conversation = await client.request(GQL_GET_CONVERSATION, {
              external_first_message_id: conversation,
              external_user_id: getState().app.id.external_user,
            });
            return data_conversation?.getAllMessagesOfStudentConversation || [];
          })
        );
        break;

      case "app/XsetReadMessage":
        await client.request(GQL_SET_READ_MESSAGE, {
          external_user_id: getState().app.id.external_user,
          external_message_id: action.payload.external_id,
        });
        dispatch(XgetUserStudentMetrics());
        break;

      case "app/XsubscribeKnowledgeAnchoringUser":
        const data_subscribe_ka_user = await client.request(
          GQL_SUBSCRIBE_MA_USER,
          {
            external_user_id: getState().app.id.external_user,
            keepHistory: true,
            email_active: true,
            email_address: action.payload,
          }
        );
        if (data_subscribe_ka_user?.subscribeMaUser?.error) {
          action.payload =
            data_subscribe_ka_user.subscribeMaUser?.message || "error";
        } else {
          dispatch(XgetKnowledgeAnchoringUserStatus());
          action.payload = false;
        }
        break;

      case "app/XgetNextKAQuestion":
        const data_next_ka_question = await client.request(
          GQL_GET_NEXT_MEMORY_ANCHORING_QUESTION,
          {
            external_user_id: getState().app.id.external_user,
          }
        );
        dispatch(XgetUserStudentMetrics());
        action.payload = data_next_ka_question?.getNextMaQuestion || null;
        break;

      case "app/XgetKnowledgeAnchoringUserStatus":
        const data_ka_user_status = await client.request(
          GQL_GET_MA_USER_STATUS,
          {
            external_user_id: getState().app.id.external_user,
          }
        );
        if (data_ka_user_status?.getMaUserStatus?.ma_active) {
          dispatch(XgetNextKAQuestion());
        }
        action.payload = data_ka_user_status?.getMaUserStatus || null;
        break;

      case "app/XanswerKnowledgeAnchoringQuestion":
        await client.request(GQL_ANSWER_MEMORY_ANCHORING_QUESTION, {
          external_user_id: getState().app.id.external_user,
          ma_question_id: parseInt(
            getState().app.knowledge_anchoring.current_question.ma_question_id
          ),
          ma_question_queue_id: parseInt(
            getState().app.knowledge_anchoring.current_question
              .ma_question_queue_id
          ),
          ma_question_queue_name:
            getState().app.knowledge_anchoring.current_question
              .ma_question_queue_name,
          ma_concept_id: parseInt(
            getState().app.knowledge_anchoring.current_question.ma_concept_id
          ),
          answer: action.payload,
        });
        dispatch(XgetNextKAQuestion());
        break;
      case "app/XsetBobInputValue":
        if (
          action.payload.length === 1 &&
          getState().app.chat.bob_input_value.length === 0
        ) {
          dispatch(
            XpostLogEvent({
              event_type: "userTyping",
              input: null,
              origin: null,
              from: null,
              userInput: null,
            })
          );
        }
        break;
      case "app/XhandleIconButtonClick":
        dispatch(
          XpostLogEvent({
            event_type: "bobClicked",
            input: null,
            origin: null,
            from: action.payload.icon_pane,
            userInput: null,
          })
        );
        break;
      case "app/XpostLogEvent":
        if (!getState().app.general.test_mode) {
          await rest_client.post("/log_event/create_log_event", {
            item: {
              event_type: action.payload?.event_type || null,
              date: new Date().getTime(),
              external_user_id: getState().app.id.external_user,
              external_course_id: getState().app.id.external_course,
              external_chapter_id: getState().app.id.external_chapter,
              external_corpus_id: getState().app.id.external_corpus,
              value: action.payload?.input || null,
              metadata: {
                from: action.payload?.from || null,
                userInput: action.payload?.userInput || null,
                origin: action.payload?.origin || null,
                window:
                  getState().app.general.iFrame_type === "responsive"
                    ? "fullscreen"
                    : getState().app.general.expanded_pane
                    ? "expanded"
                    : " regular",
                answer_type: action.payload?.answer_type || null,
                answer_id: action.payload?.answer_id || null,
                associated_question:
                  action.payload?.associated_question || null,
              },
            },
          });
        }
        break;
      case "app/XsendInputToAutoComplete":
        if (getState().app.chat.bob_input_value) {
          const data_autocomplete = await rest_client.post("/autocomplete/", {
            typing: getState().app.chat.bob_input_value,
            chapterID: String(getState().app.id.external_chapter),
            timestamp: new Date(),
          });
          const hints = [...data_autocomplete?.data?.hints] || [];
          var data_question_tag_type = null;
          action.payload = await Promise.all(
            hints.map(async (hint) => {
              data_question_tag_type = await client.request(
                GQL_GET_QUESTION_TAG_TYPE,
                {
                  external_question_id: hint.external_id,
                  external_user_id: getState().app.id.external_user,
                }
              );
              return Object.assign({}, hint, {
                type:
                  data_question_tag_type?.getQuestionTagType?.questionType ||
                  null,
                tags:
                  data_question_tag_type?.getQuestionTagType?.questionTags ||
                  [],
              });
            })
          );
        } else action.payload = [];
        break;

      case "app/XnewChatMessage":
        if (
          action.payload?.chat?.type === "answer" &&
          action.payload?.answer?.external_id
        ) {
          const data_answer_rate = await client.request(GQL_GET_ANSWER_RATE, {
            external_user_id: getState().app.id.external_user,
            external_answer_id: action.payload.answer.external_id,
          });
          action.payload.answer.rate =
            data_answer_rate?.getRateCommentAnswerByStudent?.rating || 0;
        }
        break;

      case "app/XsetAnswerRate":
        client.request(GQL_RATE_ANSWER, {
          external_user_id: getState().app.id.external_user,
          external_answer_id: action.payload.id,
          rating: action.payload.rate,
        });

        if (action.payload.rate === -1) {
          const data_suggestions = await rest_client.post("/autocomplete/", {
            typing: action.payload.original_question,
            chapterID: String(getState().app.id.external_chapter),
            timestamp: new Date(),
          });
          const hints =
            data_suggestions?.data?.hints.filter(
              (hint) => hint.text !== action.payload.original_question
            ) || [];
          if (hints.length > 0) {
            dispatch(
              XnewChatMessage({
                answer: null,
                chat: {
                  text: null,
                  type: "related-questions",
                  original_question: action.payload.original_question,
                  associated_question: null,
                },
                related_questions: hints,
                timestamp: new Date().getTime(),
                user: {
                  userID: "-1",
                },
              })
            );
          }
        }
        break;

      case "app/XaskTeacher":
        const data_ask_teacher = await client.request(GQL_ASK_TEACHER, {
          from_external_user_id: getState().app.id.external_user,
          msg_type: "question",
          content: action.payload.question,
          external_chapter_id: getState().app.id.external_chapter,
          external_corpus_id: getState().app.id.external_corpus,
          sent_at: new Date().toISOString(),
        });
        dispatch(
          XpostLogEvent({
            event_type: "askQuestionToTeacher",
            input: action.payload.question,
            origin: null,
            from: action.payload.icon_pane,
            userInput: action.payload.question,
          })
        );
        dispatch(XgetTeacherConversations());
        if (data_ask_teacher?.askTeacher?.error) {
          action.payload.error = true;
        }
        break;
      default:
        break;
    }
    return next(action);
  };

export default configureStore({
  reducer: {
    app: appReducer,
  },
  middleware: [asyncMiddleware, socketMiddleware],
});
