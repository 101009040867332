import { Input } from "antd";
import { forwardRef, useContext, useState } from "react";
import { FiSend } from "react-icons/fi";
import { GiCancel } from "react-icons/gi";
import { ThemeContext } from "../../../../../ThemeProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  XaskTeacher,
  XdisableEditAskTeacher,
} from "../../../../../../appSlice";

const ChatMessageTeacherQuestionEdit = forwardRef(
  ({ dataArrayIndex, question, focusOnChatInput }, olderMessageScrollRef) => {
    const dispatch = useDispatch();
    const commonClassNames = "flex flex-col rounded-tl-sm rounded-lg p-3 mr-4";
    const [questionInput, setQuestionInput] = useState(question);
    const handleQuestionInput = (e) => {
      setQuestionInput(e.target.value);
    };
    const { t } = useContext(ThemeContext);
    const themeColors = useSelector(
      (state) => state.app.theme.color.theme_colors
    );
    const older_message_index = useSelector(
      (state) => state.app.chat.older_message_index
    );
    const handleSendQuestion = () => {
      dispatch(
        XaskTeacher({
          question: questionInput,
          dataArrayIndex: dataArrayIndex,
          prefix: t("your-question-for-teacher"),
        })
      );
      focusOnChatInput();
    };
    const suffix = (
      <FiSend
        className={`${
          questionInput ? "text-gray-400" : "text-gray-200"
        }  hover:text-gray-500 text-base  -mr-1 cursor-pointer`}
        onClick={handleSendQuestion}
      />
    );
    return (
      <div
        className="flex flex-col items-start w-11/12"
        ref={olderMessageScrollRef}
      >
        <div
          style={{
            backgroundColor: themeColors.answer_background.base,
          }}
          className={`${commonClassNames} w-full flex items-center`}
        >
          <div className="flex items-center w-full font-medium text-gray-700">
            <div>{t("question-edit-sent")}</div>
            <div className="flex-1"></div>
            <div
              className={`text-gray-700 flex items-center ml-1 h-6 w-12 justify-center rounded-lg pl-1 pt-1 text-xl cursor-pointer`}
            >
              <GiCancel
                onClick={() => {
                  dispatch(XdisableEditAskTeacher(dataArrayIndex));
                }}
              />
            </div>
          </div>
          <div className="bg-white  border-4 border-gray-200  rounded mt-3 w-full">
            <Input
              value={questionInput}
              bordered={false}
              allowClear
              onChange={handleQuestionInput}
              onPressEnter={handleSendQuestion}
              suffix={suffix}
              autoFocus
            />
          </div>
          {/*<div className="font-semibold">{`"${questionInput}"`}</div>*/}
        </div>
      </div>
    );
  }
);

export default ChatMessageTeacherQuestionEdit;
