import { useContext } from "react";
import { BsChevronDown } from "react-icons/bs";
import { THEME } from "../../../constants/constants";
import { ThemeContext } from "../../ThemeProvider";
import ExpandButton from "../ChatPane/components/ExpandButton";
import asuLogo from "../../../assets/asu/arizona-state-university-logo.png";
import MobileMenu from "./MobileMenu";
import { XsetPaneVisible } from "../../../appSlice";
import { useDispatch, useSelector } from "react-redux";
import { getDirectiveValues } from "graphql";

const PaneHeader = () => {
  const dispatch = useDispatch();
  const { theme, t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const active_pane = useSelector((state) => state.app.general.active_pane);
  const iFrame_type = useSelector((state) => state.app.general.iFrame_type);
  const open_on_mobile_only = useSelector((state)=>state.app.general.open_on_mobile_only)

  const getTitle = () => {
    if (active_pane === "CHAT") {
      return t("pane-header-chat");
    }
    if (active_pane === "TEACHER_ANSWERS") {
      return t("pane-header-teacher-questions");
    }
    if (active_pane === "MEMORY_ANCHORING") {
      return t("pane-header-memory-anchoring");
    }
    if (active_pane === "ADAPTIVE_LEARNING") {
      return "Apprentissage Adaptatif";
    }
    if (active_pane === "INFO") {
      return t("pane-header-info");
    }
  };

  const getID = () => {
    if (active_pane === "CHAT") {
      return "title_header_chat";
    }
    if (active_pane === "TEACHER_ANSWERS") {
      return "title_header_teacher";
    }
    if (active_pane === "MEMORY_ANCHORING") {
      return "title_header_ka";
    }
    if (active_pane === "ADAPTIVE_LEARNING") {
      return "title_header_al";
    }
    if (active_pane === "INFO") {
      return "title_header_info";
    }
  }

  return (
    <div id="PaneHeader">
      <div
        style={{
          color: themeColors.primary.base,
          backgroundColor: themeColors.secondary.base,
        }}
        className={`font-bold flex items-center justify-center py-1 h-9 ${
          open_on_mobile_only && iFrame_type === "responsive"
            ? " "
            : " border-b border-gray-100 rounded-t-2xl"
        }`}
      >
        {iFrame_type !== "responsive" && (
          <div className="flex items-center p-2  pb-4 cursor-pointer">
            <ExpandButton />
          </div>
        )}
        <div className="flex-1" />
        {theme === THEME.THEME_ASU_VALUE && (
          <>
            <img
              src={asuLogo}
              alt=""
              style={{ height: "1rem" }}
              className="ml-3"
            />
            <div className="flex-1" />
          </>
        )}
        {theme === THEME.THEME_ESB_VALUE && (
          <>
            <img
              src="https://www.esbanque.fr/resources/img/main/logo.png"
              alt="ESBanque"
              style={{ height: "1.8rem" }}
              className="mr-4"
            />
          </>
        )}

        <div className="text-center" id={getID()}>{getTitle()}</div>
        <div className="flex-1" />
        {iFrame_type !== "responsive" && (
          <div className="flex items-center">
            <div
              className="p-2 pl-1 pr-3 cursor-pointer"
              onClick={() => dispatch(XsetPaneVisible(false))}
            >
              <BsChevronDown />
            </div>
          </div>
        )}
      </div>
      {open_on_mobile_only && iFrame_type === "responsive" && <MobileMenu />}
    </div>
  );
};

export default PaneHeader;
