import { Progress, Form, Input, Button, Checkbox } from "antd";
import { useContext, useEffect, useState } from "react";
import { HiOutlineFastForward } from "react-icons/hi";
import { TIMER } from "../../../constants/constants";
import { ThemeContext } from "../../ThemeProvider";
import MarkDownRender from "../ChatPane/components/MarkDownRender";
import PaneContent from "../components/PaneContent";
import { useSelector, useDispatch } from "react-redux";
import {
  XanswerKnowledgeAnchoringQuestion,
  XsetKnowledgeAnchoringStage,
  XsubscribeKnowledgeAnchoringUser,
} from "../../../appSlice";

const FirstTimeForm = () => {
  const [tcu_accepted, setTCUAccepted] = useState(false);
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const subscribe_error_message = useSelector(
    (state) => state.app.knowledge_anchoring.subscribe_error_message
  );
  const expanded_pane = useSelector((state) => state.app.general.expanded_pane);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(XsubscribeKnowledgeAnchoringUser(values.email));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      className={`flex flex-col items-center h-full ${
        expanded_pane ? "gap-4 p-20 text-sm" : "p-4 gap-3"
      }`}
    >
      <div className="font-semibold ">
        {t("welcome-knowledge-anchoring-page")}
      </div>
      <div className="flex flex-col gap-2">
        <div>
          <div
            className="font-semibold"
            style={{
              color: themeColors.primary.base,
            }}
          >
            Objectif :
          </div>
          {t("memory-anchoring-description-1")}
        </div>
        <div>
          <div
            className="font-semibold"
            style={{
              color: themeColors.primary.base,
            }}
          >
            Fonctionnement :
          </div>
          {t("memory-anchoring-description-2")}
        </div>
      </div>

      <Form
        className="flex flex-col items-center gap-3 m-0"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          className="m-0"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item className="mb-3" name="activeEmail" valuePropName="checked">
          <Checkbox
            checked={tcu_accepted}
            onChange={(e) => {
              setTCUAccepted(e.target.checked);
            }}
          >
            <a className="text-gray-500 hover:text-gray-500">
              {t("accept-tcu-1")}
            </a>
            <a
              href="https://static.professorbob.ai/cgu_esbanque-20211001.pdf"
              target="_blank"
              style={{
                color: themeColors.primary.base,
              }}
              className="font-medium rounded-md underline"
            >
              {t("accept-tcu-2")}
            </a>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            disabled={!tcu_accepted}
            className={`text-white px-6 rounded-md shadow-lg font-medium cursor-pointer text-sm border-none hover:text-white`}
            style={{
              backgroundColor: tcu_accepted
                ? themeColors.primary.base
                : "#F4F4F4",
            }}
            htmlType="submit"
          >
            {t("sign-in")}
          </Button>
        </Form.Item>
      </Form>
      <div>{subscribe_error_message}</div>
    </div>
  );
};

const Stage1 = () => {
  const current_question = useSelector(
    (state) => state.app.knowledge_anchoring.current_question
  );

  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);
  const status = useSelector(
    (state) => state.app.knowledge_anchoring.user_status
  );

  if (status) {
    if (status.error) {
      if (status.message === "Knowledge Anchoring Entry not found") {
        return <FirstTimeForm />;
      } else {
        return <>erreur</>;
      }
    } else {
      if (status.ma_active) {
        if (current_question.error) {
          return (
            <div className="h-80 flex flex-col items-center justify-center">
              <div className="mb-3 font-bold ">
                {t("welcome-knowledge-anchoring-page")}
              </div>
              <div className="mb-3 px-6 text-center">
                {t("you-dont-have-knowledge-anchoring-for-today")}
              </div>
              <div className="px-6 text-center">
                {t("memory-anchoring-come-again-tomorow")}
              </div>
              {!status.ma_email_active && <div>proposer d'activer le mail</div>}
            </div>
          );
        } else {
          return (
            <div className="h-80 flex flex-col items-center justify-center">
              <div className="m-3 mb-6">
                {t("memory-anchoring-3-questions")}
              </div>
              <div
                style={{
                  backgroundColor: hovered
                    ? themeColors.primary.base
                    : themeColors.primary.base,
                }}
                onMouseLeave={() => setHovered(false)}
                onMouseEnter={() => setHovered(true)}
                className="flex items-center justify-center rounded h-9 text-white px-6 cursor-pointer"
                onClick={() => dispatch(XsetKnowledgeAnchoringStage(2))}
              >
                {t("memory-anchoring-lets-go")}
              </div>
            </div>
          );
        }
      } else {
        return <>MA désactivé</>;
      }
    }
  }
};

const AnswerButtons = () => {
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const [hoveredNO, setHoveredNO] = useState(false);
  const [hoveredYES, setHoveredYES] = useState(false);

  const dispatch = useDispatch();

  return (
    <div className="flex space-x-3 my-6 mt-6">
      <div
        style={{
          color: hoveredNO
            ? themeColors.primary.base
            : themeColors.primary.base,
          borderColor: hoveredNO
            ? themeColors.primary.base
            : themeColors.primary.base,
        }}
        className={`flex items-center justify-center rounded h-9 bg-white px-3 cursor-pointer border-2 font-medium`}
        onMouseEnter={() => setHoveredNO(true)}
        onMouseLeave={() => setHoveredNO(false)}
        onClick={() => dispatch(XanswerKnowledgeAnchoringQuestion(false))}
      >
        {t("memory-anchoring-user-answers-I-am-learning-it")}
      </div>
      <div
        style={{
          backgroundColor: hoveredYES
            ? themeColors.primary.base
            : themeColors.primary.base,
        }}
        onMouseEnter={() => setHoveredYES(true)}
        onMouseLeave={() => setHoveredYES(false)}
        className={`flex items-center justify-center rounded h-9 text-white px-3 cursor-pointer font-medium`}
        onClick={() => dispatch(XanswerKnowledgeAnchoringQuestion(true))}
      >
        {t("memory-anchoring-user-answers-I-knew-it")}
      </div>
    </div>
  );
};

const MemoryAnchoringPane = () => {
  const stage = useSelector((state) => state.app.knowledge_anchoring.stage);
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const [timeLeft, setTimeLeft] = useState(TIMER.TIMER_VALUE * 100);

  const current_question = useSelector(
    (state) => state.app.knowledge_anchoring.current_question
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (stage === 2) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 10);
      if (!timeLeft) {
        dispatch(XsetKnowledgeAnchoringStage(3));
      }
      return () => clearInterval(intervalId);
    }
  }, [stage, timeLeft]);

  useEffect(() => {
    setTimeLeft(TIMER.TIMER_VALUE * 100);
  }, [stage]);

  return (
    <PaneContent>
      <div className="flex flex-col h-full justify-center items-center overflow-y-auto py-6 invisible-scrollbar">
        {stage === 1 && <Stage1 />}
        {stage === 2 && (
          <div className="flex flex-col flex-1 justify-center items-center">
            <div className="m-3 mb-0 text-gray-500 text-3xs">
              {current_question.ma_concept_label}
            </div>
            <div className="mx-10 text-base font-medium text-center">
              {current_question.question_text}
            </div>
            <div className="mt-6 w-5/6 flex items-center">
              <Progress
                showInfo={false}
                size="small"
                percent={timeLeft / TIMER.TIMER_VALUE}
                strokeColor="rgb(99, 102, 241)"
              />
              <div
                style={{
                  color: themeColors.primary.base,
                }}
                className={`text-lg ml-3 mt-0.5`}
                onClick={() => dispatch(XsetKnowledgeAnchoringStage(3))}
              >
                <HiOutlineFastForward />
              </div>
            </div>
          </div>
        )}
        {stage === 3 && (
          <div className="flex flex-col flex-1 h-full justify-center items-center">
            <div className="m-3 mt-14 sm:mt-3 mb-0 text-gray-500 text-3xs">
              {current_question.ma_concept_label}
            </div>
            <div className="mx-10 text-base font-medium text-center">
              {current_question.question_text}
            </div>
            <div className="mt-8 w-5/6 flex flex-col">
              <MarkDownRender source={current_question.answer_text} />
            </div>
            <AnswerButtons />
          </div>
        )}
        {stage === 4 && (
          <div
            className="flex flex-col flex-1 h-full justify-center items-center text-gray-500"
            onClick={() => {}}
          >
            <div className="mb-3 font-bold ">
              {t("memory-anchoring-congratulations")}
            </div>
            <div className="mb-3 px-6 text-center">
              {t("memory-anchoring-come-again")}
            </div>
            <div className="px-6 text-center">
              {t("memory-anchoring-come-again-content")}
            </div>
          </div>
        )}
      </div>
    </PaneContent>
  );
};

export default MemoryAnchoringPane;
