import { useContext, useState } from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { RiVipCrown2Fill } from "react-icons/ri";
import MarkDownRender from "./../../../components/MarkDownRender";
import { ThemeContext } from "../../../../../ThemeProvider";
import HintLabel from "./HintLabel";
import { useDispatch, useSelector } from "react-redux";
import { sendQuestionToBob, XsetQuestionForLogBuffer } from "../../../../../../appSlice";

const HintsItem = ({ hint, index }) => {
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);
  const bob_input_value = useSelector(
    (state) => state.app.chat.bob_input_value
  );
  const dispatch = useDispatch();
  return (
    <div
      style={
        hovered
          ? {
              backgroundColor: themeColors.primary.base,
            }
          : {}
      }
      className={`ml-2 rounded px-1 py-1 hover:text-white flex items-center ${
        hovered ? ` text-white ` : "text-gray-600"
      }`}
      key={index}
      onMouseEnter={() => {
        setExpanded(true);
        setHovered(true);
      }}
      onMouseLeave={() => {
        setExpanded(false);
        setHovered(false);
      }}
    >
      <div
        id={"suggestion_autocomplete_"+(index+1)}
        className="flex-1 flex items-center cursor-pointer"
        onClick={() => {
          dispatch(
            XsetQuestionForLogBuffer({
              event_type: "askQuestionToBob",
              input: hint.text,
              origin: "hints",
              from: null,
              userInput: bob_input_value,
            })
          );
          dispatch(sendQuestionToBob(hint.text));
        }}
      >
        <div
          className={`text-3xs pr-1 hover:text-current ${
            hint.score > 1.91 && index === 0
              ? "text-yellow-300"
              : "text-gray-300"
          }`}
        >
          {hint.score > 1.91 && index === 0 ? (
            <RiVipCrown2Fill />
          ) : (
            <BsFillCaretRightFill />
          )}
        </div>
        {hint.highlight[0] ? (
          expanded ? (
            <MarkDownRender compact source={hint.text} />
          ) : (
            <MarkDownRender compact source={hint.highlight[0]} />
          )
        ) : hint.text.length > 100 ? (
          expanded ? (
            <MarkDownRender compact source={hint.text} />
          ) : (
            <MarkDownRender
              compact
              source={`${hint.text.substring(0, 80)} ${t("see-more-hints")}`}
            />
          )
        ) : (
          <MarkDownRender compact source={hint.text} />
        )}
      </div>
      <HintLabel className="flex-1" hint={hint} />
    </div>
  );
};

export default HintsItem;
