import { useRef } from "react";
import PaneContent from "../components/PaneContent";
import InputAndHints from "./components/InputAndHints/InputAndHints";
import Onboarding from "./components/Onboarding";
import ChatMessages from "./components/ChatMessages/ChatMessages";
import { useSelector } from "react-redux";

const ChatPane = () => {
  const chatInputRef = useRef(null);
  const focusOnChatInput = () => {
    chatInputRef.current.focus();
  };

  const chat_data_array = useSelector((state)=> state.app.chat.chat_data_array)

  return (
    <>
      <PaneContent>
        
        {chat_data_array.length > 0 ? (
          <ChatMessages
            focusOnChatInput={focusOnChatInput}
          />
        ) : (
          <Onboarding />
        )}
      </PaneContent>
        <InputAndHints
          chatInputRef={chatInputRef}
        />
    </>
  );
};

export default ChatPane;
