import { Progress } from "antd";
import { useContext, useEffect, useState } from "react";
import { HiOutlineFastForward } from "react-icons/hi";
import { TIMER } from "../../../constants/constants";
import { ThemeContext } from "../../ThemeProvider";
import MarkDownRender from "../ChatPane/components/MarkDownRender";
import PaneContent from "../components/PaneContent";
import { useSelector } from "react-redux";

const demo_questions = {
  "3WA": [
    {
      context: "HTML",
      question: "Comment inclure une feuille de style en HTML ?",
      answer:
        "Il y a deux méthodes principales pour placer une feuille de style. La première consiste à placer la feuille de style dans l'entête de document. La seconde consiste à placer la feuille de style dans un fichier séparé, et à y faire référence dans l'entête du document.",
    },
    {
      context: "CSS",
      question: "Pourquoi utilise-t-on le langage CSS ?",
      answer:
        "Le CSS permet de modifier la forme d'une balise HTML, d'une classe (nom que l'on peut donner à plusieurs balises pour les différencier), ou d'un élément en particulier.",
    },
    {
      context: "Balises",
      question: "Comment utiliser la balise IMG ?",
      answer:
        "Pour mettre une image simple sur une page web, nous utiliserons l'élément <img> . C'est un élément vide (ce qui signifie qu'il ne contient ni texte ni balise de fermeture) qui demande au moins un attribut pour fonctionner — src (souvent appelé par son nom entier: source).",
    },
  ],
  ASU: [
    {
      context: "Series",
      question: "What are absolute maximum and minimum values?",
      answer:
        "An absolute maximum point is a point where the function obtains its greatest possible value. Similarly, an absolute minimum point is a point where the function obtains its least possible value.",
    },
    {
      context: "Calculus",
      question: "What is antiderivative?",
      answer:
        "Antiderivatives are the opposite of derivatives. An antiderivative is a function that reverses what the derivative does. One function has many antiderivatives, but they all take the form of a function plus an arbitrary constant. Antiderivatives are a key part of indefinite integrals.",
    },
    {
      context: "Calculus",
      question: "What is approximation by Simpson’s Rule?",
      answer:
        "Simpson's Rule is based on the fact that given any three points, you can find the equation of a quadratic through those points. For example, let's say you had points (3, 12), (1, 5), and (5, 9). Then you could solve this system of equations for a, b, and c, and get the equation of the quadratic.",
    },
  ],
  ESBanque: [
    {
      context: "Crédit",
      question: "Qu'est-ce qu'une marge de crédit ?",
      answer:
        "Si vous avez besoin d’argent, mais que ce besoin est assez variable dans le temps, et n’est pas centré uniquement autour d’un bien ou d’un service, alors la marge de crédit est une solution à envisager.\n\nSigne d'euro pour une demande d'argent Une fois votre demande de prêt acceptée, la marge de crédit est une sorte de trésorerie à laquelle vous avez accès tout le temps, et dont vous pouvez faire l’utilisation que vous souhaitez.\n\n Contrairement à un prêt personnel, dans le cadre de la marge de crédit, vous ne payez des intérêts que sur le montant que vous utilisez parmi la somme mise à votre disposition.\n\nVous ne payez pas l’intérêt sur la totalité de la somme qui vous est proposée. Toutefois, comme pour un crédit classique, vous devez rembourser à la fois le capital et une part d’intérêts",
    },
    {
      context: "Assurance Vie",
      question: "Comment l'assureur gère le capital d'une assurance vie ?",
      answer:
        "L'assureur place une partie des primes collectées pour les faire fructifier et réinvestit les revenus financiers ainsi obtenus de manière à accroître la somme initiale.\n\nAinsi, il constitue un capital qui lui servira à payer, au moment voulu, la prestation prévue dans le contrat.",
    },
    {
      context: "Assurance",
      question:
        "Qu'est-ce que l'information préalable et la police d’assurance ?",
      answer:
        "**L'information préalable :** avant toute conclusion de contrat, l'assureur doit remettre à l'assuré ce document : il contient une fiche d'information, le prix, les garanties, un projet de contrats, la notice d'information et les pièces annexes. Ce document permet à l'assuré de connaître les garanties, les exclusions et ses obligations.\n\n **La police d'assurance :** il s'agit du contrat d'assurance composé des conditions générales et particulières. La police doit impérativement contenir la durée du contrat et la faculté de renonciation (classique pour les assurances non-vie et 30 jours calendaires pour les assurances-vie).",
    },
  ],
  cengage: [
    {
      context: "Reagan area",
      question: "What were Reagon political priorities in 1981?",
      answer:
        "The Reagan administration focused on three major priorities: cutting federal taxes, reorienting the national government’s relationship to economic matters, and boosting national security capabilities.",
    },
    {
      context: "Reagan area",
      question:
        "What happened to the unionized workers during Reagan Presidency?",
      answer:
        "A In 1981 Reagan fired then ation’s air traffic controllers after their union refused to halt a nationwide strike. The action portended more aggressive anti-union strategies that both his administration and business interests would pursue during the 1980s. The percentage of unionized workers fell to just 16 percent by the end of Reagan’s presidency.",
    },
    {
      context: "Reagan area",
      question: "What was the SDI ?",
      answer:
        "In 1983 Reagan proposed the most expensive defense system in history: a space-based shield against incoming missiles. This Strategic Defense Initiative (SDI) soon had its own Pentagon agency, which sought $26 billion, over five years, for researchcosts alone. Controversy swirled around SDI. Skeptics dubbed it “Star Wars” and shuddered at its astronomical costs. Although most scientists dismissed the plan as too speculative, Congress voted appropriations for SDI. Reagan insisted that a defen-sive missile shield could work and also suggested that the Soviet Union might collapse under the economic strain of competing in an accelerating arms race.",
    },
  ],
};

const MemoryAnchoringContainer = () => {
  const demo_client = useSelector((state) => state.app.general.demo_client);
  const [stage, setStage] = useState(1);
  const [questions, setQuestions] = useState(demo_questions.ASU);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const [timeLeft, setTimeLeft] = useState(TIMER.TIMER_VALUE * 100);
  const [hovered, setHovered] = useState(false);
  const [hoveredNO, setHoveredNO] = useState(false);
  const [hoveredYES, setHoveredYES] = useState(false);

  useEffect(() => {
    demo_questions[demo_client] && setQuestions(demo_questions[demo_client]);
  }, []);

  useEffect(() => {
    if (stage === 2) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 100);
      if (!timeLeft) {
        setStage(3);
      }
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
  }, [stage, timeLeft]);

  useEffect(() => {
    if (currentQuestionIndex > 0) {
      if (currentQuestionIndex < questions.length) {
        setStage(2);
      } else {
        setStage(4);
      }
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    setTimeLeft(TIMER.TIMER_VALUE * 100);
  }, [stage]);

  return (
    <div className="h-full w-full flex flex-col items-center flex-1 justify-center overflow-y-auto py-6 ">
      {stage === 1 && (
        <div className="flex flex-col items-center justify-center">
          <div className="m-3 mb-6">{t("memory-anchoring-3-questions")}</div>
          <div
            style={{
              backgroundColor: hovered
                ? themeColors.primary.base
                : themeColors.primary.base,
            }}
            onMouseLeave={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            className="flex items-center justify-center rounded h-9 text-white px-6 cursor-pointer"
            onClick={() => setStage(2)}
          >
            {t("memory-anchoring-lets-go")}
          </div>
        </div>
      )}
      {stage === 2 && (
        <div className="flex flex-col flex-1 justify-center items-center">
          <div className="m-3 mb-0 text-gray-500 text-3xs">
            {questions[currentQuestionIndex]?.context}
          </div>
          <div className="mx-10 text-base font-medium text-center">
            {questions[currentQuestionIndex]?.question}
          </div>
          <div className="mt-6 w-5/6 flex items-center">
            <Progress
              showInfo={false}
              size="small"
              percent={timeLeft / TIMER.TIMER_VALUE}
              strokeColor={themeColors.primary.base}
            />
            <div
              style={{
                color: themeColors.primary?.base,
              }}
              className={`text-lg ml-3 mt-0.5 cursor-pointer`}
              onClick={() => setStage(3)}
            >
              <HiOutlineFastForward />
            </div>
          </div>
        </div>
      )}
      {stage === 3 && (
        <div className="flex flex-col flex-1 justify-center items-center">
          <div className="m-3 mt-14 sm:mt-3 mb-0 text-gray-500 text-3xs">
            {questions[currentQuestionIndex]?.context}
          </div>
          <div className="mx-10 text-base font-medium text-center">
            {questions[currentQuestionIndex]?.question}
          </div>
          <div className="mt-8 w-5/6 flex flex-col">
            <MarkDownRender
              source={questions[currentQuestionIndex]?.answer || ""}
            />
          </div>
          <div className="flex space-x-3 my-6 mt-6">
            <div
              style={{
                color: hoveredNO
                  ? themeColors.primary.base
                  : themeColors.primary.base,
                borderColor: hoveredNO
                  ? themeColors.primary.base
                  : themeColors.primary.base,
              }}
              className={`flex items-center justify-center rounded h-9 bg-white px-3 cursor-pointer border-2 font-medium`}
              onMouseEnter={() => setHoveredNO(true)}
              onMouseLeave={() => setHoveredNO(false)}
              onClick={() => {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
              }}
            >
              {t("memory-anchoring-user-answers-I-am-learning-it")}
            </div>
            <div
              style={{
                backgroundColor: hoveredYES
                  ? themeColors.primary.base
                  : themeColors.primary.base,
              }}
              onMouseEnter={() => setHoveredYES(true)}
              onMouseLeave={() => setHoveredYES(false)}
              className={`flex items-center justify-center rounded h-9 text-white px-3 cursor-pointer font-medium`}
              onClick={() => {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
              }}
            >
              {t("memory-anchoring-user-answers-I-knew-it")}
            </div>
          </div>
        </div>
      )}
      {stage === 4 && (
        <div
          className="flex flex-col flex-1 h-full justify-center items-center text-gray-500"
          onClick={() => {}}
        >
          <div className="mb-3 font-bold ">
            {t("memory-anchoring-congratulations")}
          </div>
          <div className="mb-3 px-6 text-center">
            {t("memory-anchoring-come-again")}
          </div>
          <div className="px-6 text-center">
            {t("memory-anchoring-come-again-content")}
          </div>
        </div>
      )}
    </div>
  );
};

const MemoryAnchoringPaneDEMO = () => {
  return (
    <>
      <PaneContent>
        <MemoryAnchoringContainer></MemoryAnchoringContainer>
      </PaneContent>
    </>
  );
};

export default MemoryAnchoringPaneDEMO;
