const resources = {
  default: {
    translation: {
      // chat
      "first-message": "Hi! I'm here to help you! 😄",
      "onboarding-message-1":"👋 Hi, I am the ASU Tutor Bot. I'm here to answer all your questions about the course 📖",
      "onboarding-message-2":"Let's try, ask your first question or click on a suggestion bellow👇",
      "question-sent": "Your question has been sent. We will try to respond as soon as possible. Please note that it may take up to 48 hours for a tutor to respond during regular business days.",
      "question-edit-sent": "Try rephrasing your question for the tutor. ",
      no: "No",
      yes: "Yes",
      "question-confirm": "Would you like to ask a tutor or connect with a live tutor on Zoom (available M-F 11am-5pm AZ time) ?",
      "see-less": "see less",
      "see-more": "see more",
      expand: "Expand",
      contract: "Contract to short answer",
      "explore-source": "Research your source material.",
      // hints:
      "see-more-hints": "(see more)",
      // video
      video: "Video",
      loop: "Loop",
      speed: "speed",
      start: "Start",
      end: "End",
      // app
      "conversation-starter": "👋 Hello! Ready to learn?",
      "pane-header-all-widgets": "Dashboard",
      "pane-header-chat": "Ask the ASU Tutor Bot!",
      "pane-header-teacher-questions": "Tutor Answers",
      "pane-header-memory-anchoring": "Knowledge Anchoring",
      "pane-header-settings": "Settings",
      "pane-header-info":"Info",
      "ask-bob": "Ask the ASU Tutor Bot!",
      "your-question-for-teacher": "Your question to teachers:",
      //tooltip
      "tooltip-chat": "Ask me some questions",
      "tooltip-teacher": "Access to the teachers' answers",
      "tooltip-KA": "Practice with the Knowledge Anchoring",
      "tooltip-AL": "Test yourself with Adaptive Learning",
      "tooltip-info": "Hi, I'm Bob...",
      // teacher answers
      "mark-unread": "mark as unread",
      "teacher-answer-new-question": "Do you have new questions? ",
      "refresh": "Refresh",
      // memory anchoring
      "accept-tcu-1":"I have read and accept the ",
      "accept-tcu-2":"terms and conditions",
      "memory-anchoring-3-questions":
        "Review and memorize with some questions",
      "memory-anchoring-lets-go": "Let's go!",
      "memory-anchoring-user-answers-I-knew-it": "I knew it!",
      "memory-anchoring-user-answers-I-am-learning-it": "Now I know!",
      "memory-anchoring-congratulations": "🎉 Congratulations!",
      "memory-anchoring-come-again": "Come again tomorrow and keep learning!",
      "memory-anchoring-come-again-content": "Will you do better than today?",
      // dashboard
      "dashboard-memory-anchoring-title": "Knowledge Anchoring",
      "dashboard-memory-anchoring-content":
        "Test yourself with 3 new questions.\n\nGo check them out!",
      "dashboard-teacher-questions-title": "Teacher Answers",
      "dashboard-teacher-questions-content-has-answers":
        "You have new answers!",
      "dashboard-teacher-questions-content-has-no-answers":
        "Chat and ask teachers!",
      "dashboard-chat-title": "ASU Tutor Bot",
      "dashboard-chat-content": "ASU tutor Bot is here to help! Ask away!",
      // settings
      "bob-language": "Bob Language:",
      "bob-language-en": "English",
      "bob-language-fr": "French",
      "settings-position-label": "Position:",
      "settings-position-right": "Right",
      "settings-position-left": "Left",
      "settings-sidebar-position-label": "Sidebar Position:",
      "settings-sidebar-position-center": "Center",
      "settings-sidebar-position-bottom": "Bottom",
      theme: "Theme:",
      "theme-default": "ASU",
      "coming-soon": "Coming soon!",
      "welcome-knowledge-anchoring-page": "Welcome to the Knowledge Anchoring ! 😄",
      "you-dont-have-knowledge-anchoring-for-today": "There isn't any question for this time.",
      "memory-anchoring-come-again-tomorow": "Come again tomorrow !",
      "memory-anchoring-description-1": "This method allows you to memorize what you learn more efficiently and durably.",
      "memory-anchoring-description-2": "Sign up to receive 3 questions a day that will stimulate your mind and improve your knowledge retention.",
      "enable-email-reminders": "Enable email reminders",
      "sign-in": "Sign In",
      "is-this-useful": "Is this answer useful?",
      "similar-question": "Here are some similar questions:",
      "similar-question-original-question": "Here are some similar questions for your search ",
      "bob-found-answer":"I hope this answers your question ! 😄",
      "bob-found-similar-question":"Here is the answer I found for the following similar question"

    },
  }
};

export default resources