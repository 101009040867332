import { useContext, useEffect } from "react";
import { Spin } from "antd";
import { useState } from "react";
import { PANES } from "../../../constants/constants";
import { ReactComponent as LetterCLosed } from "../../../assets/svgLetterClosed.svg";
import { ReactComponent as LetterOpen } from "../../../assets/svgLetterOpen.svg";
import PaneContent from "../components/PaneContent";
import { ThemeContext } from "../../ThemeProvider";
import { ReloadOutlined, VideoCameraFilled } from "@ant-design/icons";
import SvgChatbubbleOutline from "../../../assets/SvgChatBubble";
import { useDispatch, useSelector } from "react-redux";
import {
  XgetTeacherConversations,
  XsetActivePane,
  XsetReadMessage,
  XgetUserStudentMetrics,
} from "../../../appSlice";

const IndigoBackground = ({ children, onClick }) => {
  return (
    <div
      className={`flex flex-col bg-gray-100 rounded-md items-start text-white cursor-pointer`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
const GrayBackground = ({ children, onClick }) => {
  return (
    <div
      className={`flex flex-col bg-gray-100 rounded-md items-start text-gray-900`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const TeacherAnswer = ({ conversation, dataArrayIndex }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const [question, setQuestion] = useState(
    conversation.find((q) => q.external_first_message_id === null)
  );
  const [answers, setAnswers] = useState(
    conversation.filter((a) => a.external_first_message_id !== null)
  );
  const [unread, setUnread] = useState(
    conversation
      .filter((a) => a.external_first_message_id !== null)
      .some((b) => b.read_at === null)
  );

  useEffect(() => {
    setQuestion(conversation.find((q) => q.external_first_message_id === null));
    setAnswers(conversation.filter((a) => a.external_first_message_id !== null));
    setUnread(
      conversation
        .filter((a) => a.external_first_message_id !== null)
        .some((b) => b.read_at === null)
    );
  }, [conversation]);

  if (conversation.length === 1)
    return (
      <GrayBackground>
        <div className="flex flex-1 justify-start items-center px-3 py-2">
          <div className={`font-medium text-gray-400`}>
            Q: {question?.content}
          </div>
        </div>
      </GrayBackground>
    );

  if (unread)
    return (
      <IndigoBackground
        onClick={() => {
          setOpen(true);
          answers.forEach((answer) => {
            if (answer.read_at === null) {
              dispatch(
                XsetReadMessage({
                  dataArrayIndex: dataArrayIndex,
                  external_id: answer.external_id,
                })
              );
            }
          });
        }}
      >
        <div
          style={{
            backgroundColor: themeColors.primary.base,
          }}
          className={`w-full rounded-md flex items-center p-3`}
        >
          <LetterCLosed className={`text-2xl mr-2`} />
          <div className="font-medium ">Q: {question?.content} </div>
        </div>
        {open && (
          <div className="p-3 text-black">
            <div className={`${open ? "" : "line-clamp-1"} space-y-1 my-1`}>
              {answers
                ?.sort((a, b) => a.sent_at - b.sent_at)
                .map((reply, i) => (
                  <div className="" key={i}>
                    {reply.content}
                  </div>
                ))}
            </div>
          </div>
        )}
      </IndigoBackground>
    );

  return (
    <GrayBackground>
      <div
        style={{
          borderColor: themeColors.primary.base,
        }}
        className={`border w-full rounded-md`}
      >
        <div
          className={`bg-gray-200 w-full rounded-md flex items-center p-3 cursor-pointer`}
          onClick={() => setOpen(!open)}
        >
          <LetterOpen className="text-gray-500 text-2xl mr-2" />
          <div className="font-medium text-gray-900">
            Q: {question?.content}{" "}
          </div>
        </div>

        {open && (
          <div className="p-3">
            <div className={`whitespace-pre-line space-y-1 my-1`}>
              {answers
                ?.sort((a, b) => b.sent_at - a.sent_at)
                .map((reply, i) => (
                  <div>
                    <div key={i}>{reply.content}</div>
                    {i < answers.length - 1 && (
                      <div className="w-11/12 border m-3"></div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </GrayBackground>
  );
};

const TeacherAnswerPane = () => {
  const dispatch = useDispatch();
  const { t } = useContext(ThemeContext);
  const themeColors = useSelector(
    (state) => state.app.theme.color.theme_colors
  );
  const conversations = useSelector((state) => state.app.teacher.conversations);
  const external_client_id = useSelector(
    (state) => state.app.id.external_client
  );
  const [isSpinning, setSpin] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleReload = () => {
    dispatch(XgetUserStudentMetrics());
    setSpin(true);
    setTimeout(() => setSpin(false), 2000);
  };

  useEffect(() => {
    if (conversations.length === 0) {
      dispatch(XgetTeacherConversations());
    }
  }, []);

  const handleZoomButton = () => {
    window.open("https://asu.zoom.us/j/81868714615", "_blank");
  };

  const ZoomLinkForASU = () => {
    if (external_client_id === "wI8uqv3BcEh2r5") {
      return (
        <div>
          or connect to Zoom center{" "}
          <VideoCameraFilled
            style={{
              color: themeColors.primary.base,
            }}
            className={`mx-1 my-auto text-xl cursor-pointer`}
            onClick={() => {
              handleZoomButton();
            }}
          />{" "}
          during opening hours (11am-5pm Monday-Friday)
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <PaneContent>
        <div className="space-y-2 p-2 mb-2 h-full">
          <div
            style={{
              backgroundColor: hovered
                ? themeColors.primary.strong
                : themeColors.primary.base,
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="w-1/3 mx-auto flex items-center justify-center rounded h-6 text-white px-3 cursor-pointer font-medium"
            onClick={() => handleReload()}
          >
            {t("refresh")}
            <Spin
              className="flex"
              indicator={
                <ReloadOutlined
                  spin={isSpinning ? true : false}
                  style={{ fontSize: "14px" }}
                  className={`ml-3 text-white items-center justify-center`}
                />
              }
            />
          </div>
          {conversations?.slice(0).map((conversation, i) => {
            return (
              <div key={i}>
                <TeacherAnswer conversation={conversation} dataArrayIndex={i} />
              </div>
            );
          })}
          <div className="items-center text-center flex flex-col px-3">
            <div className="flex items-center text-center">
              <div>{t("teacher-answer-new-question") + t("ask-bob")}</div>
              <SvgChatbubbleOutline
                style={{ width: 22, color: themeColors.primary.base }}
                className={`ml-1 cursor-pointer`}
                onClick={() => {
                  dispatch(XsetActivePane(PANES.CHAT));
                }}
              />
            </div>
            <ZoomLinkForASU />
          </div>
        </div>
      </PaneContent>
    </>
  );
};

export default TeacherAnswerPane;
