function SvgChatbubbleOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="chatbubble-outline_svg__ionicon"
      viewBox="0 0 512 512"
      // width="14px"
      // height="14px"
      {...props}
    >
      {/*stdDeviation is how much to blur*/}
      {/* <!-- slope is the opacity of the shadow --> */}
      <filter id="dropshadowChatBubble">
        <feGaussianBlur in="SourceAlpha" stdDeviation="6" result="blur" />
        <feOffset dx="20" dy="20" result="offsetblur" />
        <feOffset dx="-15" dy="-20" result="offsetblur2" in="blur" />
        <feComponentTransfer result="shadow1" in="offsetblur">
          <feFuncA type="linear" slope="0.2" />
        </feComponentTransfer>
        <feComponentTransfer result="shadow2" in="offsetblur2">
          <feFuncA type="linear" slope="0.2" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode in="shadow1" />
          {/* <feMergeNode in="shadow2" /> */}
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <path
        d="M87.49 380c1.19-4.38-1.44-10.47-3.95-14.86a44.86 44.86 0 00-2.54-3.8 199.81 199.81 0 01-33-110C47.65 139.09 140.73 48 255.83 48 356.21 48 440 117.54 459.58 209.85a199 199 0 014.42 41.64c0 112.41-89.49 204.93-204.59 204.93-18.3 0-43-4.6-56.47-8.37s-26.92-8.77-30.39-10.11a31.09 31.09 0 00-11.12-2.07 30.71 30.71 0 00-12.09 2.43l-67.83 24.48a16 16 0 01-4.67 1.22 9.6 9.6 0 01-9.57-9.74 15.85 15.85 0 01.6-3.29z"
        fill="currentColor"
        //fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={46}
        filter="url(#dropshadowChatBubble)"
        // transform="scale (-1, 1)"
        // transform-origin="center"
        // strokeWidth={39.4}
      />
    </svg>
  );
}

export default SvgChatbubbleOutline;
