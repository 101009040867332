import { createSlice } from "@reduxjs/toolkit";
import Color from "color";
import { LAYOUT, PANES } from "./constants/constants";
import defaultMessages from "./client_custom/default";
import { getALConcepts } from "./libs/getALConcepts";
import { isMobile, isMobileOnly } from "mobile-device-detect";
import { transformCustomData } from "./libs/transformCustomData";
import resources from "./components/Pane/AdaptiveLearning/3wa";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    id: {
      external_client: null,
      external_course: null,
      external_corpus: null,
      external_chapter: null,
      external_user: null,
    },
    notification: {
      teacher: 0,
      chat: 1,
      knowledge_anchoring: 0,
      adaptive_learning: 0,
    },
    current: {
      video_played_id: null,
      image_focused: null,
    },

    theme: {
      loaded: false,
      logo: false,
      messages: defaultMessages.en,
      color: {
        primary: Color("#5868FE"),
        secondary: Color("#FFFFFF"),
        answer_background: Color("#F4F4F4"),
        theme_colors: null,
      },
    },
    general: {
      open_on_mobile_only: isMobileOnly,
      open_on_mobile: isMobile,
      expanded_pane: false,
      sidebar_position: LAYOUT.SIDEBAR_POSITION.CENTER,
      bob_position: LAYOUT.BOB_POSITION.RIGHT,
      nb_auto_suggestions: 5,

      conversation_starter_visible: false,
      active_pane: null,
      pane_visible: false,

      iFrame_type: "extension",
      knowledge_anchoring_visible: false,
      adaptive_learning_visible: false,
      disable_conversation_starter: false,
      demo_client: false,
      test_mode: false,
      dev_mode: false,
      exam_mode: false,
    },
    chat: {
      onboarding_questions: [],
      bob_input_value: "",
      bob_suggestions: [],
      chat_data_array: [],
      external_first_message_id: null,
      history_offset: 0,
      loading: true,
      older_message_index: null,
      question_for_log_buffer: null
    },
    teacher: {
      conversations: [],
    },
    knowledge_anchoring: {
      current_question: null,
      stage: 1,
      user_status: null,
      subscribe_error_message: false,
    },
    adaptive_learning: {
      stage: "welcome",
      current_concept: null,
      concepts: [],
      questions: [],
      current_question_index: 0,
      nb_bad_answer: null,
      score: null,
      previous_scores: [],
    },
  },
  reducers: {
    XgetALConcepts: (state, action) => {
      state.adaptive_learning.previous_scores = action.payload;
      if (state.adaptive_learning.concepts.length === 0) {
        state.adaptive_learning.concepts = getALConcepts();
      }
    },
    XsetALStage: (state, action) => {
      state.adaptive_learning.stage = action.payload;
    },
    XsetALCurrentConcept: (state, action) => {
      state.adaptive_learning.current_concept = action.payload;
      if (action.payload) {
        var questions = resources[action.payload.name].questions
          .sort(() => Math.random() - 0.5)
          .slice(0, 10);
        state.adaptive_learning.questions = questions;
        state.adaptive_learning.nb_bad_answer = questions.filter(
          (question) => question.state === 4
        ).length;
        state.adaptive_learning.score =
          (10 - questions.filter((question) => question.state === 4).length) *
          10;
      }
    },
    XsetALCurrentQuestionIndex: (state) => {
      state.adaptive_learning.current_question_index =
        state.adaptive_learning.questions.findIndex(
          (question) => question.state === 0
        );
    },
    XsetALQuestions: (state, action) => {
      state.adaptive_learning.questions = action.payload;
      state.adaptive_learning.nb_bad_answer = action.payload.filter(
        (question) => question.state === 4
      ).length;
      state.adaptive_learning.score =
        (10 -
          action.payload.filter((question) => question.state === 4).length) *
        10;
    },
    XresetALQuestions: (state) => {
      state.adaptive_learning.questions = [];
      state.adaptive_learning.nb_bad_answer = null;
      state.adaptive_learning.score = null;
      state.adaptive_learning.current_question_index = 0;
      state.adaptive_learning.stage = "concepts";
    },
    XpostALScore: (state) => {},
    XanswerKnowledgeAnchoringQuestion: (state, action) => {},
    XsubscribeKnowledgeAnchoringUser: (state, action) => {
      state.knowledge_anchoring.subscribe_error_message = action.payload;
    },
    XgetKnowledgeAnchoringUserStatus: (state, action) => {
      state.knowledge_anchoring.user_status = action.payload;
    },
    XsetKnowledgeAnchoringStage: (state, action) => {
      state.knowledge_anchoring.stage = action.payload;
    },
    XgetNextKAQuestion: (state, action) => {
      state.knowledge_anchoring.current_question = action.payload;
      if (state.knowledge_anchoring.stage === 3) {
        if (!action.payload.error) {
          state.knowledge_anchoring.stage = 2;
        } else state.knowledge_anchoring.stage = 4;
      }
    },
    XsetReadMessage: (state, action) => {
      state.teacher.conversations[action.payload.dataArrayIndex][
        state.teacher.conversations[action.payload.dataArrayIndex].findIndex(
          (a) => a.external_id === action.payload.external_id
        )
      ].read_at = new Date();
    },
    XgetTeacherConversations: (state, action) => {
      state.teacher.conversations = action.payload.sort(
        (a, b) =>
          b.find((c) => c.external_first_message_id === null).sent_at -
          a.find((c) => c.external_first_message_id === null).sent_at
      );
    },
    XaskTeacher: (state, action) => {
      var newChatDataArray = [...state.chat.chat_data_array];
      newChatDataArray.splice(action.payload.dataArrayIndex, 1);
      newChatDataArray.push({
        chat: {
          text: action.payload.prefix + " \n " + action.payload.question,
          type: "chat",
          original_question: null,
        },
        timestamp: new Date().getTime(),
        user: {
          userID: "1",
        },
      });
      newChatDataArray.push({
        answer: null,
        chapterID: null,
        chat: {
          text: action.payload.error
            ? "error"
            : state.theme.messages.translation["question-sent"],
          type: "chat",
          original_question: null,
        },
        conversationID: null,
        courseID: null,
        related_questions: null,
        timestamp: new Date(),
        user: {
          userID: "0",
          username: null,
        },
      });
      state.chat.chat_data_array = newChatDataArray;
    },
    XsetAnswerRate: (state, action) => {
      state.chat.chat_data_array[action.payload.dataArrayIndex].answer.rate =
        action.payload.rate;
    },
    XsetBobInputValue: (state, action) => {
      state.chat.bob_input_value = action.payload;
    },
    XsendInputToAutoComplete: (state, action) => {
      state.chat.bob_suggestions = action.payload;
    },
    XresetBobSuggestions: (state) => {
      state.chat.bob_suggestions = [];
    },
    XsetExternalIDs: (state, action) => {
      state.id.external_user = action.payload.checked_user || null;
      state.id.external_course = action.payload.course || null;
      state.id.external_corpus = action.payload.corpus || null;
      state.id.external_chapter = action.payload.chapter || null;
      state.id.external_client = action.payload.client || null;

      state = transformCustomData(state, action.payload.custom);
    },
    XsetTestMode: (state, action) => {
      state.general.test_mode = action.payload;
    },
    XsetDevMode: (state, action) => {
      state.general.dev_mode = action.payload;
    },
    XsetIframeType: (state, action) => {
      state.general.iFrame_type = action.payload;
      if (action.payload === "responsive") {
        state.notification.chat = 0;
        state.general.disable_conversation_starter = true;
        state.general.conversation_starter_visible = false;
        state.general.bob_position = LAYOUT.BOB_POSITION.LEFT;
        state.general.pane_visible = true;
        state.general.active_pane = PANES.CHAT;
      }
    },
    XsetDemoClient: (state, action) => {
      state.general.demo_client = action.payload;
      state.general.knowledge_anchoring_visible = true;
    },
    XsetBobPosition: (state, action) => {
      state.general.bob_position = action.payload;
    },
    XsetLocale: (state, action) => {
      state.theme.messages =
        action.payload === "fr" ? defaultMessages.fr : defaultMessages.en;
    },
    XsetExpandedPane: (state, action) => {
      state.general.expanded_pane = action.payload;
    },
    XsetConversationStarterVisible: (state, action) => {
      state.general.conversation_starter_visible = action.payload;
    },
    XsetActivePane: (state, action) => {
      state.general.active_pane = action.payload;
    },
    XsetDisableConversationStarter: (state, action) => {
      state.general.disable_conversation_starter = action.payload;
    },
    XsetChatDataArray: (state, action) => {
      state.chat.chat_data_array = action.payload;
    },
    XsetPaneVisible: (state, action) => {
      state.general.pane_visible = action.payload;
    },
    XnewChatMessage: (state, action) => {
      state.chat.chat_data_array = [
        ...state.chat.chat_data_array,
        action.payload,
      ];
      state.chat.chat_data_array = state.chat.chat_data_array
        .slice()
        .sort((a, b) => {
          return a.timestamp - b.timestamp;
        });
    },
    XpostLogEvent: (state, action) => {},
    XsetQuestionForLogBuffer: (state, action) => {
      state.chat.question_for_log_buffer = action.payload
    },
    XgetUserStudentMetrics: (state, action) => {
      state.notification.teacher = action.payload.teacher_notification;
      state.notification.knowledge_anchoring =
        action.payload.knowledge_notification;
      state.general.exam_mode = action.payload.exam_mode;
      if (action.payload.exam_mode) {
        state.general.pane_visible = false;
        state.general.active_pane = null;
      }
    },
    XhandleIconButtonClick: (state, action) => {
      if (state.general.iFrame_type === "responsive") {
        state.general.active_pane = action.payload.icon_pane;
      } else {
        if (
          state.general.pane_visible === true &&
          state.general.active_pane === action.payload.icon_pane
        ) {
          state.general.pane_visible = false;
          state.general.active_pane = null;
        } else {
          state.notification.chat = 0;
          state.general.pane_visible = true;
          state.general.active_pane = action.payload.icon_pane;
          state.general.conversation_starter_visible = false;
        }
      }
    },
    XdisableAskTeacher: (state, action) => {
      var newChatDataArray = [...state.chat.chat_data_array];
      newChatDataArray[action.payload].chat.text =
        state.theme.messages["question-confirm"];
      state.chat.chat_data_array = newChatDataArray;
    },
    XdisableEditAskTeacher: (state, action) => {
      var newChatDataArray = [...state.chat.chat_data_array];
      newChatDataArray.splice(action.payload, 1);
      state.chat.chat_data_array = newChatDataArray;
    },
    XsetVideoPlayedID: (state, action) => {
      state.current.video_played_id = action.payload;
    },
    XsetImageFocused: (state, action) => {
      state.current.image_focused = action.payload;
    },
    initSocket: (state, action) => {},
    closeSocket: (state, action) => {},
    sendQuestionToBob: (state, action) => {
      state.chat.bob_input_value = "";
    },
    XgetBobChatHistory: (state, action) => {
      state.chat.loading = false;
      state.chat.history_offset += 25;
      if (
        state.chat.external_first_message_id === null &&
        action.payload.external_first_message_id
      ) {
        state.chat.external_first_message_id =
          action.payload.external_first_message_id;
      }
    },
    XsaveChatMessage: (state, action) => {
      state.chat.history_offset += 1;
      if (
        state.chat.external_first_message_id === null &&
        action.payload.external_id
      ) {
        state.chat.external_first_message_id = action.payload.external_id;
      }
    },
    XsetChatLoading: (state) => {
      state.chat.loading = true;
      state.chat.older_message_index = state.chat.chat_data_array.length;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  XgetALConcepts,
  XsetALStage,
  XsetALCurrentConcept,
  XsetALCurrentQuestionIndex,
  XpostALScore,
  XsetALQuestions,
  XresetALQuestions,
  XanswerKnowledgeAnchoringQuestion,
  XsubscribeKnowledgeAnchoringUser,
  XgetKnowledgeAnchoringUserStatus,
  XsetKnowledgeAnchoringStage,
  XsetReadMessage,
  XaskTeacher,
  XsetAnswerRate,
  XsetExternalIDs,
  XsetTestMode,
  XsetDevMode,
  XsetIframeType,
  XsetDemoClient,
  XsetBobPosition,
  XsetLocale,
  XsetExpandedPane,
  XpostLogEvent,
  XsetQuestionForLogBuffer,
  XgetUserStudentMetrics,
  XgetNextKAQuestion,
  XsetConversationStarterVisible,
  XsetDisableConversationStarter,
  XsetActivePane,
  XsetPaneVisible,
  XsetChatDataArray,
  XdisableEditAskTeacher,
  XdisableAskTeacher,
  XnewChatMessage,
  initSocket,
  closeSocket,
  sendQuestionToBob,
  XhandleIconButtonClick,
  XsetVideoPlayedID,
  XsetImageFocused,
  XsendInputToAutoComplete,
  XsetBobInputValue,
  XresetBobSuggestions,
  XgetTeacherConversations,
  XgetBobChatHistory,
  XsetChatLoading,
  XsaveChatMessage,
} = appSlice.actions;

export default appSlice.reducer;
